
















































import { BrowserStorageService, ServiceManager, ServiceType, API, Config } from 'client-website-ts-library/services'
import { Component, Vue } from 'vue-property-decorator'
import { Office } from 'client-website-ts-library/types'

import Navigation from './Navigation.vue'
import DropDownList from '../UI/DropDownList.vue'

@Component({
  components: {
    Navigation,
    DropDownList,
  },
})
export default class Header extends Vue {
  private selectedOffice: string | null = null;

  private selectedCallOffice: string | null = null;

  private offices: Office[] = [];

  private callOffices: Office[] = [];

  private isStuck = false;

  private mobileNavActive = false;

  /* Configuration Options */
  private gradient = false;

  private fixed = false;

  private stickyNav = true;

  private eventListenerOpts: AddEventListenerOptions | EventListenerOptions = { passive: true };

  get month(): number {
    return (new Date().getMonth() + 1)
  }

  mounted() {
    this.checkScroll()

    this.$router.afterEach(() => {
      this.mobileNavActive = false
    })

    API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
      this.offices = offices.sort((a, b) => a.OfficeName.localeCompare(b.OfficeName))
      this.callOffices = offices

      // Loop through the offices array and update the office with ID 123
      this.offices.forEach((office) => {
        if (office.Id === '676ffee8-1d19-4be6-976c-b32be6ca2932') {
          office.OfficeName = 'Central - Townsville'
        }
      })

      this.callOffices.forEach((office) => {
        if (office.Id === '676ffee8-1d19-4be6-976c-b32be6ca2932') {
          office.OfficeName = 'Central - Townsville'
        }
      })
    })

    this.offices.push()
  }

  selectOffice(): void {
    const browserStorage = ServiceManager.Require<BrowserStorageService>(ServiceType.BrowserStorage)

    browserStorage.setItem('ep_selected_office', this.selectedOffice)

    const office = this.offices.find((tmp) => tmp.Id === this.selectedOffice)

    if (office != null) {
      this.$router.push(`/offices/${office.OfficeSlug}`)
    }
  }

  selectCallOffice(): void {
    const office = this.offices.find((tmp) => tmp.Id === this.selectedCallOffice)

    if (office != null) {
      window.location.href = `tel:${office.Phone}`
    }
  }

  checkScroll(): void {
    this.isStuck = window.scrollY > 0
  }

  created() {
    window.addEventListener('scroll', this.checkScroll, this.eventListenerOpts)
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScroll, this.eventListenerOpts)
  }
}
